<h3>Aprobar acción</h3>
<div>
    <form [formGroup]="approveActionForm" (ngSubmit)="submitApproveAction()">
        <mat-form-field class="w-100">
            <mat-label>Comentario</mat-label>
            <textarea matInput formControlName="comment"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="5">
            </textarea>
        </mat-form-field>
        <div class="d-flex justify-content-end gap-1">
            <button type="button" (click)="closeDialog()" mat-raised-button>Cancelar</button>
            <button type="submit" mat-raised-button color="primary">Confirmar</button>
        </div>
    </form>
</div>