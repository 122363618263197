import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResoluteDealActionRequest } from '@app/models/DealActions/DealActionRequests';
import { DealActionToResolute } from '@app/models/DealActions/DealActionToResolute';
import { DealActionsService } from '@app/services/deal-actions.service';

@Component({
  selector: 'app-resolve-deal-action',
  templateUrl: './resolve-deal-action.component.html',
  styleUrls: ['./resolve-deal-action.component.scss']
})
export class ResolveDealActionComponent implements OnInit {

  public form: FormGroup;
  constructor(public dialogRef: MatDialogRef<ResolveDealActionComponent>,
    private _dealActionService: DealActionsService,
    @Inject(MAT_DIALOG_DATA) public data: DealActionToResolute,
    private _fb: FormBuilder) {
      this.form = this._fb.group({
        dealId: [''],
        dealActionId: [''],
        comment: ['']
      });
    }

  ngOnInit(): void {
    this.form.patchValue({
      dealId: this.data.dealId,
      dealActionId: this.data.dealActionId
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  submitResolve() {
    const request: ResoluteDealActionRequest = {
      dealId: this.form.get('dealId')?.value,
      dealActionId: this.form.get('dealActionId')?.value,
      comment: this.form.get('comment')?.value
    };

    this._dealActionService.resolveDealActionData(request)
    .subscribe(res => this.dialogRef.close(res.data));
  };


}
