<!--sidebar-->
<c-sidebar
  #sidebar="cSidebar"
  class="d-print-none sidebar sidebar-fixed"
  id="sidebar"
  visible
>
  <c-sidebar-brand
    [brandFull]="{
      src: 'assets/img/brand/LOGO-INGE-SERVFINANCIEROS-BLANCO-EDDEF2022.png',
      width: 175,
      height: 50,
      alt: 'CoreUI Logo'
    }"
    [brandNarrow]="{
      src: 'assets/img/brand/LOGO-INGE-SERVFINANCIEROS-BLANCO-EDDEF2022.png',
      width: 46,
      height: 46,
      alt: 'CoreUI Logo'
    }"
    routerLink="./"
  >
  </c-sidebar-brand>
    <perfect-scrollbar [config]="perfectScrollbarConfig">
  <!--  [perfectScrollbar]="perfectScrollbarConfig"-->
  <c-sidebar-nav
    [navItems]="navItems"
  >
  </c-sidebar-nav>
    </perfect-scrollbar>
  <c-sidebar-toggler
    *ngIf="!sidebar.narrow"
    toggle="unfoldable"
    cSidebarToggle="sidebar"
  ></c-sidebar-toggler>
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-default-header class="mb-4 d-print-none header header-sticky" position="sticky" sidebarId="sidebar"></app-default-header>
  <!--app-body-->
  <div class="body flex-grow-1 px-3">
    <c-container fluid class="h-auto">
      <router-outlet></router-outlet>
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer></app-default-footer>
</div>
