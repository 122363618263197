import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserDTO } from '@app/models/UserModels';
import { UpdateUserRequest } from '@app/models/Users/UpdateUser';
import { AuthService } from '@app/services/auth/auth.service';
import { UserService } from '@app/services/user.service';

@Component({
  selector: 'app-modal-change-pass',
  templateUrl: './modal-change-pass.component.html',
  styleUrls: ['./modal-change-pass.component.scss'],
})
export class ModalChangePassComponent implements OnInit {
  public hidePass!: boolean;
  public disabledBtn: boolean = false;
  public showError: boolean = false;
  public formUpdate!: FormGroup;
  public userId: string = '';
  public userDetail!: UserDTO;
  public dialogRef!: MatDialogRef<ModalChangePassComponent>;
  // matcher = new MyErrorStateMatcher();

  constructor(
    private _fb: FormBuilder,
    private _userService: UserService,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.formUpdate = this._fb.group({
      password: ['', Validators.minLength(2)],
      passwordCheck: [''],
    });
    this.userId = this._authService.getUserId();
    this.getUserDetail();
  }

  getUserDetail() {
    const fetchUserToForm$ = this._userService.getUserById(this.userId);
    fetchUserToForm$.subscribe((e) => (this.userDetail = e));
  }

  checkPass(): void {
    const submitedPass = this.formUpdate.get('password')?.value;
    const check = this.formUpdate.get('passwordCheck')?.value;

    if (submitedPass !== check) {
      this.showError = true;
    } else {
      this.showError = false;
    }
  }

  public async submitUpdate() {
    const { id, funnels, name, lastName, rut } = this.userDetail;
    const submitedPass = this.formUpdate.get('password')?.value;

    const hashPassword = submitedPass
      ? await this._userService.hashPassword(submitedPass)
      : null;
    const request: UpdateUserRequest = {
      userId: id,
      name,
      lastName,
      rut,
      funnels,
      password: hashPassword,
    };

    this._userService.updateUser(request).subscribe({
      next: (res) => this.dialogRef.close(res.data),
    });
  }
}
