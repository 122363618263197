import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarrantiesRoutingModule } from './warranties-routing.module';
import { WarrantiesComponent } from './warranties.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//Material
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';

import { DatePipe } from './pipe/orderDate.pipe';
import { Ng9RutModule } from 'ng9-rut';
import { FiltersComponent } from './components/filters/filters.component';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkMenuModule } from '@angular/cdk/menu';

import { DealActionsModule } from '@app/features/deal-actions/deal-actions.module';
import {ModalCommentComponent} from './components/modals/modal-comment/modal-comment.component'


@NgModule({
  declarations: [WarrantiesComponent, FiltersComponent, DatePipe, ModalCommentComponent],
  imports: [
    CommonModule,
    WarrantiesRoutingModule,
    FontAwesomeModule,
    Ng9RutModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    CdkMenuModule,
    MatFormFieldModule,
    MatChipsModule,
    MatInputModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    DealActionsModule,
    MatMenuModule,
  ],
})
export class WarrantiesModule {}
