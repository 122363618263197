import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DealsActions } from '../../interfaces/dealActions.interface';
import { DealsService } from '../../services/deals.service';
import { Deal } from '../../interfaces/deal.interface';

@Component({
  selector: 'app-edit-content-action',
  templateUrl: './edit-content-action.component.html',
  styleUrls: ['./edit-content-action.component.scss'],
})
export class EditContentActionComponent implements OnInit {
  public formUpdate: FormGroup;
  public deals!: Deal;
  public dealsExecutive!: string;
  public show: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EditContentActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DealsActions,
    private _fb: FormBuilder,
    private dealService: DealsService
  ) {
    this.formUpdate = this._fb.group({
      amountEdit: ['',],
      commissionEdit: [''],
    });
  }

  ngOnInit(): void {
    // this.fillUpdateAction(this.data);
    // this.dealService
    //   .getDeals(this.data.dealId)
    //   .subscribe((e) => 
    //   this.showAmount(e.executive)
    //   // console.log(e)
    //   );
      console.log(this.data)
  }
  
  private fillUpdateAction(action: DealsActions) {
    this.formUpdate.patchValue({
      amountEdit: action.amount,
      commissionEdit: action.commission,
    });
  }

  private showAmount(value:string): void {
    if (value == this.data.dealExecutive) {
      this.show = true
    }
  }

  public async submitUpdate() {
    const submitedPass = this.formUpdate.get('password')?.value;
  }

}
