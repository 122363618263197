<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1"
    >
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>
    <c-header-nav
      class="d-none d-lg-flex me-auto"
      style="position: relative; left: 380px"
    >
      <!-- <c-nav-item>
        <a cNavLink routerLink="/dashboard" routerLinkActive="active">
          Tablero
        </a>
      </c-nav-item>
      <c-nav-item>
        <a cNavLink routerLink="/users" routerLinkActive="active">Ejecutivo</a>
      </c-nav-item>
      <c-nav-item>
        <a cNavLink routerLink="/settings" routerLinkActive="active">
          Ajustes
        </a>
      </c-nav-item> -->
    </c-header-nav>

    <!-- Barra superior derecha (notificaciones, lista, mail) -->
    <!-- Router link para la ruta -->
    <c-header-nav class="d-none d-lg-flex">
      <!-- <c-nav-item>      
          <a routerLink="./" cNavLink>
          <svg cIcon name="cilBell" size="lg"></svg> 
        </a>


      </c-nav-item> -->
      <!-- <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilList" size="lg"></svg>
        </a>
      </c-nav-item> -->
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <!-- <svg cIcon name="cilEnvelopeOpen" size="lg"></svg> -->
        </a>
      </c-nav-item>
    </c-header-nav>
    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>
  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
    <span style="font-weight: bold; color: rgb(85, 85, 85);">UF: {{ uf | currency : "CLP" : "symbol":'1.2-2' }} &nbsp;&nbsp;&nbsp;  USD: {{ usd | currency : "CLP" : "symbol":'1.2-2' }}  &nbsp;&nbsp;&nbsp; UTM: {{ utm | currency : "CLP" : "symbol":'1.2-2' }} </span>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button cButton color="" [caret]="false" cDropdownToggle class="py-0">
      <!-- Iso Wanak superior izquierdo -->
      <c-avatar
        cTextColor="primary"
        shape="rounded-1"
        size="xs"
        src="./assets/img/brand/ISOLOGO-INGE-SGR2022.svg"
        status="success"
      >
      </c-avatar>
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Cuenta</h6>
      </li>
      <!-- <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilUser"></svg>
          Perfil
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilChart"></svg>
          Mis indicadores
        </a>
      </li> -->
      <li>
        <a class="p-2" href="" routerLink="" (click)="openDialog()" cDropdownItem>
          <fa-icon class="mx-2" [icon]="faLockOpen"></fa-icon>
          Cambiar contraseña
        </a>
      </li>
      <li class="">
        <a routerLink="" class="p-2" (click)="logout()" cDropdownItem>
          <fa-icon class="mx-2" [icon]="faChevronLeft"></fa-icon>
          Cerrar sesión
        </a>
      </li>
      <li>
        <!-- <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name='cilInbox'></svg>
          Mensajes
          <c-badge class="ms-2 float-end" color="warning">
            2
          </c-badge>
        </a> -->
      </li>
      <!-- <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Settings</h6>
      </li>
      <li></li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilSettings"></svg>
          Settings
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilCreditCard"></svg>
          Payments
          <c-badge class="ms-2 float-end" color="secondary">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilFile"></svg>
          Projects
          <c-badge class="ms-2 float-end" color="primary">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <hr cDropdownDivider/>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
          Lock Account
        </a>
      </li>
      <li></li>
    </ul>
  </c-dropdown>
</ng-template> -->
    </ul></c-dropdown
  ></ng-template
>
