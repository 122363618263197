<form cForm [formGroup]="formFilter">
  <article class="container__header">
    <section>
      <mat-form-field class="width-search w-50" appearance="outline">
        <mat-label>Busqueda RUT / Nombre</mat-label>
        <input
          matInput
          type="text"
          formControlName="searchByRutName"
          (keyup.enter)="inputChangeValue($event)"
        />
      </mat-form-field>
    </section>
  </article>
  <mat-accordion class="pb-5">
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Filtros</mat-panel-title>
      </mat-expansion-panel-header>
      <article class="container__header">
        <section>
          <mat-form-field class="width-search" appearance="outline">
            <mat-label>Estados</mat-label>
            <mat-select
              formControlName="actionFilter"
              cSelect
              (selectionChange)="changeStatus($event.value)"
            >
              <mat-option
                *ngFor="let value of actionsValue"
                value="{{ value.value }}"
                >{{ value.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </section>
        <section>
          <mat-form-field class="width-search" appearance="outline">
            <mat-label>Envios</mat-label>
            <mat-select
              formControlName="actionName"
              cSelect
              (selectionChange)="changeActionName($event.value)"
            >
              <mat-option
                *ngFor="let v of dealActionOptions"
                value="{{ v.name }}"
                >{{ v.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </section>
        <section>
          <mat-form-field class="width-search" appearance="outline">
            <mat-label>Etiquetas</mat-label>
            <mat-select
              (selectionChange)="changeActionName($event.value)"
              formControlName="tag"
            >
              <mat-option value="">Todos</mat-option>
              <mat-option *ngFor="let tag of actionTags" [value]="tag">
                {{ tag }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </section>
        <section>
          <mat-form-field class="width-search" appearance="outline">
            <mat-label>Ejecutivos</mat-label>
            <mat-select
              (selectionChange)="changeActionName($event.value)"
              formControlName="dataExecutive"
            >
              <mat-option value="">Todos</mat-option>
              <mat-option *ngFor="let dataExecutive of executives" [value]="dataExecutive.id">
                {{ dataExecutive.name }}
                {{ dataExecutive.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </section>
        <section>
          <mat-form-field class="width-search" appearance="outline">
            <mat-label>Finalidad</mat-label>
            <input
              formControlName="warrantyType"
              matInput
              (keyup.enter)="inputChangeValue($event)"
            />
          </mat-form-field>
        </section>
        <section>
          <mat-form-field class="width-search">
            <mat-label>Fecha de Registro</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="registerDate"
              (dateChange)="inputChangeValue($event)"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              style="height: 18px !important"
              #picker
            ></mat-datepicker>
          </mat-form-field>
        </section>
      </article>
    </mat-expansion-panel>
  </mat-accordion>
</form>
