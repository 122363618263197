<h3 mat-dialog-title>Editar etiquetas</h3>
<form [formGroup]="updateTagsForm" (ngSubmit)="submitUpdate()">
    <ng-select
    class="mb-3"
    appendTo="body"
    [items]="allTags$ | async"
    [multiple]="true"
    [closeOnSelect]="false"
    placeholder="Etiquetas"
    [addTag]="true"
    [addTagText]="'Nueva etiqueta'"
    formControlName="tags">
    </ng-select>
    <div class="d-flex justify-content-end gap-1">
        <button type="button" (click)="closeDialog()" mat-raised-button>Cancelar</button>
        <button type="submit" mat-raised-button color="primary">Confirmar</button>
    </div>
</form>