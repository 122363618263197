<app-filters
  [actionTags]="(allTags$ | async)!"
  (onEnter)="receiveFilter($event)"
  (onSelectActions)="receiveAction($event)"

></app-filters>

<mat-spinner *ngIf="showSpinner"></mat-spinner>
<div [hidden]="showSpinner">
  <div class="mt-3 x-scrollable-table">
    <table
      mat-table
      [dataSource]="source"
      matSort
      matSortDirection="asc"
      class="mat-elevation-z8"
      matSortStart="asc"
      matSortActive="creationDate"
    >
      <ng-container matColumnDef="isActiveClient">
        <th mat-header-cell *matHeaderCellDef>Activo</th>
        <td mat-cell *matCellDef="let element">
          <fa-icon
            size="xl"
            [styles]="{ color: element.isActiveClient ? 'blue' : 'lightgray' }"
            [icon]="icons.faCheckCircle"
            [title]="
              element.isActiveClient ? 'Cartera Activa' : 'Cartera Inactiva'
            "
          ></fa-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Nombre Cliente
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.companyName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="companyRut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Rut Cliente</th>
        <td mat-cell *matCellDef="let element">
          {{ element.companyRut | rut }}
        </td>
      </ng-container>
      <ng-container matColumnDef="buyerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Nombre Mandante
        </th>
        <td mat-cell *matCellDef="let element">{{ element.buyerName }}</td>
      </ng-container>
      <ng-container matColumnDef="warrantyType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Finalidad</th>
        <td mat-cell *matCellDef="let element">{{ element.warrantyType }}</td>
      </ng-container>
      <ng-container matColumnDef="commission">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Comision</th>
        <td mat-cell *matCellDef="let element">
          {{ element.commission | number }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dealExecutive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ejecutivo</th>
        <td mat-cell *matCellDef="let element">{{ element.dealExecutive }}</td>
      </ng-container>
      <ng-container matColumnDef="actionName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Enviado a :</th>
        <td
          mat-cell
          *matCellDef="let element"
          style="text-decoration: underline; color: blue; cursor: pointer"
          (click)="dealDialogOpen(element.dealId)"
        >
          {{ element.actionName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="creationDate">
        <th
          aria-sort="ascending"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Fecha :
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.creationDate | date : "short" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Monto</th>
        <td mat-cell *matCellDef="let element">
          {{ element.amount | number }}
        </td>
      </ng-container>

      <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Comentario</th>
        <td mat-cell *matCellDef="let element" class="cell-4">
          <p class="text-overflow link-dialog" (click)="openDialog(  element.comment )">
            {{ element.comment }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="tags">
        <th
          mat-header-cell
          class="column-tags"
          *matHeaderCellDef
          mat-sort-header
        >
          Etiquetas
        </th>
        <td mat-cell class="column-tags" *matCellDef="let element">
          <mat-chip-list class="p-1" aria-label="Etiquetas de acción">
            <mat-chip *ngFor="let tag of element.tags">
              {{ tag }}
            </mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>
      <ng-container matColumnDef="hasActiveWarranties" [ngSwitch]="accion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Accion</th>
        <td mat-cell *matCellDef="let element" class="display-cell">
          <ng-container *ngSwitchCase="0">
            <span
              (click)="openApprovingActionForm(element)"
              title="Aprobar"
              style="cursor: pointer; color: #00c851"
            >
              <fa-icon
                style="color: #00c851"
                [icon]="icons.faCheckCircle"
              ></fa-icon>
            </span>

            <span
              title="Pendiente"
              style="cursor: pointer; color: orange"
              (click)="openPendingActionForm(element)"
            >
              <fa-icon
                style="color: orange"
                [icon]="icons.faExclamationTriangle"
              ></fa-icon>
            </span>

            <span
              (click)="openRejectActionForm(element)"
              title="Rechazar"
              style="cursor: pointer; color: red"
            >
              <fa-icon
                style="color: red"
                [icon]="icons.faTimesCircle"
              ></fa-icon>
            </span>
          </ng-container>
          <ng-container *ngSwitchCase="1" class="aprobados">
            <p style="color: #00c851; font-weight: 500" class="my-3">
              Aprobados
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <p style="color: #cc0000; font-weight: 500" class="my-3">
              Rechazados
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="3">
            <p style="color: orange; font-weight: 500" class="my-3">
              Pendiente
              <br />
              de Datos
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="4">
            <p style="color: #00c851; font-weight: 500" class="my-3">
              Accion Restringida
            </p>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [cdkContextMenuTriggerFor]="menu"
      >
        <ng-template #menu>
          <div
            cdkMenu
            class="context_menu mat-elevation-z8"
            style="display: flex; flex-direction: column"
          >
            <button
              class="context-menu-item"
              cdkMenuItem
              mat-stroked-button
              (click)="updateDealActionTags(row)"
              style="background-color: white !important"
            >
              Editar
              <fa-icon [icon]="icons.faPen"></fa-icon>
            </button>
            <button
              (click)="openEditAction(row)"
              cdkMenuItem
              mat-stroked-button
              style="background-color: white !important"
              class="context-menu-item"
            >
              Editar Montos
              <fa-icon [icon]="icons.faDollar"></fa-icon>
            </button>
          </div>
        </ng-template>
      </tr>
    </table>
  </div>
  <mat-paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons
    #paginator
    aria-label="Selecciona los elementos de la tabla"
  >
  </mat-paginator>
</div>
