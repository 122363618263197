import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';

import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';

import { TextFieldModule } from '@angular/cdk/text-field';

const MaterialModule = [
  MatButtonModule,
  MatSelectModule,
  MatCheckboxModule,
  MatInputModule,
  MatFormFieldModule,
  MatChipsModule,
  MatDialogModule
];

import { RegisterActionFormComponent } from './components/register-action-form/register-action-form.component';
import { ApproveDealActionComponent } from './components/approve-deal-action/approve-deal-action.component';
import { RejectActionFormComponent } from './components/reject-action-form/reject-action-form.component';
import { PendingActionFormComponent } from './components/pending-action-form/pending-action-form.component';
import { UpdateDealActionTagsFormComponent } from './components/update-deal-action-tags-form/update-deal-action-tags-form.component';
import { ResolveDealActionComponent } from './components/resolve-deal-action/resolve-deal-action.component';



@NgModule({
  declarations: [
    RegisterActionFormComponent,
    ApproveDealActionComponent,
    RejectActionFormComponent,
    PendingActionFormComponent,
    UpdateDealActionTagsFormComponent,
    ResolveDealActionComponent,
    ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MaterialModule,
    TextFieldModule,
    NgxMaskModule,
    NgSelectModule
  ],
  exports: [
    RegisterActionFormComponent,
    RejectActionFormComponent,
    ApproveDealActionComponent,
    UpdateDealActionTagsFormComponent
  ]
})
export class DealActionsModule { }
