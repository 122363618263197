<ng-container>
  <article>
    <section>
      <h2>Editar Contacto</h2>
    </section>
    <section>
      <form
        [formGroup]="contactForm"
        id="formEdit"
        cForm
        (ngSubmit)="submitUpdateCompany()"
      >
        <section>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Nombre:</mat-label>
            <input
              matInput
              cFormControl
              type="text"
              formControlName="clientName"
            />
          </mat-form-field>
          <p
            class="text-danger"
            *ngIf="
              contactForm.get('clientName')?.touched &&
              contactForm.get('clientName')?.errors
            "
          >
            Este Dato es Requerido
          </p>
        </section>
        <section>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Apellido:</mat-label>
            <input
              matInput
              cFormControl
              type="text"
              formControlName="clientLastName"
            />
          </mat-form-field>
          <p
            class="text-danger"
            *ngIf="
              contactForm.get('clientLastName')?.touched &&
              contactForm.get('clientLastName')?.errors
            "
          >
            Este Dato es Requerido
          </p>
        </section>
        <section>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Telefono:</mat-label>
            <input matInput cFormControl type="text" formControlName="phone" />
          </mat-form-field>
          <p
            class="text-danger"
            *ngIf="
              contactForm.get('phone')?.touched &&
              contactForm.get('phone')?.errors
            "
          >
            Este Dato es Requerido
          </p>
        </section>
        <section>
          <mat-form-field   appearance="outline" class="w-100">
            <mat-label>Correo Electrónico:</mat-label>
            <input    matInput cFormControl  formControlName="email" />
          </mat-form-field>
          <p
            class="text-danger"
            *ngIf="
              contactForm.get('email')?.touched &&
              contactForm.get('email')?.errors
            "
          >
            Este Dato es Requerido
          </p>
        </section>
        <section>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Cargo:</mat-label>
            <input matInput cFormControl type="text" formControlName="cargo" />
          </mat-form-field>
          <p
            class="text-danger"
            *ngIf="
              contactForm.get('cargo')?.touched &&
              contactForm.get('cargo')?.errors
            "
          >
            Este Dato es Requerido
          </p>
        </section>

        <div
          mat-dialog-actions
          class="d-flex justify-content-between align-items-center mt-3 w-100"
        >
          <button type="button" class="btn btn-outline-danger" (click)="onNoClick()">
            Cerrar
          </button>

          <button class="btnEdit" cButton type="submit">Actualizar</button>
        </div>
      </form>
    </section>
  </article>
</ng-container>
