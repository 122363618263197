import { Injectable } from '@angular/core';
import { DealsActions } from '@app/views/deals/interfaces/dealActions.interface';
import {
  ActionName,
  FiltersActionsName,
} from '../interfaces/actionDeal.interface';
import { VirtualRowModelGenerator } from '@syncfusion/ej2-angular-grids';

@Injectable({
  providedIn: 'root',
})
export class ActionBoardService {
  constructor() {}

  public filterNameRutDealsActions(
    fetchActionsItems: Array<DealsActions>,
    filters: FiltersActionsName
  ): Array<DealsActions> {
    let filteringDeals = [...fetchActionsItems];
    //Filtro por rut, nombre y acciones
    if (filters.searchByRutName) {
      filteringDeals = filteringDeals.filter(
        (d) =>
          d.companyName
            .toLocaleLowerCase()
            .includes(filters.searchByRutName.toLocaleLowerCase()) ||
          d.companyRut
            .toLocaleLowerCase()
            .includes(
              filters.searchByRutName
                .replace(/\./g, '')
                .replace('-', '')
                .toLocaleLowerCase()
            )
      );
    }
    //Filtro por ejecutivo
    if (filters.dataExecutive) {
      filteringDeals = filteringDeals.filter(
        (e) =>
          e.requestingUserEmail !== undefined &&
          e.requestingUserEmail
            .toLocaleLowerCase()
            .includes(filters.dataExecutive.toLocaleLowerCase())
      );
    }
    //Filtro por tipo de estaods
    if (filters.actionName) {
      filteringDeals = filteringDeals.filter((e) =>
        e.actionName
          .toLocaleLowerCase()
          .includes(filters.actionName.toLocaleLowerCase())
      );
    }
    //Filtros por finalidad
    if (filters.warrantyType) {
      filteringDeals = filteringDeals.filter(
        (e) =>
          e.warrantyType !== undefined &&
          e.warrantyType.includes(filters.warrantyType)
      );
    }
    //Filtros por fecha
    if (filters.registerDate) {
      filteringDeals = filteringDeals.filter(
        (d) =>
          new Date(d.creationDate).getUTCDate() ==
          new Date(filters.registerDate).getUTCDate()
      );
    }

    //Filter by selected tag
    if (filters.tag) {
      filteringDeals = filteringDeals.filter((a) =>
        a.tags?.includes(filters.tag)
      );
    }

    return filteringDeals;
  }
}
