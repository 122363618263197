import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResolutePendingDealActionRequest } from '@app/models/DealActions/DealActionRequests';
import { DealActionToResolute } from '@app/models/DealActions/DealActionToResolute';
import { DealActionsService } from '@app/services/deal-actions.service';

@Component({
  selector: 'app-pending-action-form',
  templateUrl: './pending-action-form.component.html',
  styleUrls: ['./pending-action-form.component.scss'],
})
export class PendingActionFormComponent implements OnInit {
  public pendingActionForm!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PendingActionFormComponent>,
    private _dealActionService: DealActionsService,
    @Inject(MAT_DIALOG_DATA) public data: DealActionToResolute,
    private _fb: FormBuilder
  ) {
    this.pendingActionForm = this._fb.group({
      dealId: [''],
      actionId: [''],
      comment: [],
    });
  }

  ngOnInit(): void {
    this.pendingActionForm.patchValue({
      dealId: this.data.dealId,
      actionId: this.data.dealActionId,
    });

  }

  closeDialog = () => {
    this.dialogRef.close();
  };

  submitPendingAction = () => {
    const request: ResolutePendingDealActionRequest = {
      dealId: this.pendingActionForm.get('dealId')?.value,
      dealActionId: this.pendingActionForm.get('actionId')?.value,
      resolutionComment: this.pendingActionForm.get('comment')?.value,
    };
    this._dealActionService.pendingDealAction(request)
    .subscribe(res => {
      this.dialogRef.close(res);
    })
    // this.dealService.dealsPendingData(request).subscribe((e) => console.log(e));
    // this.dialogRef.close(request);
  };
}
