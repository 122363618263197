import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './containers';
import { Page404Component } from './views/pages/page404/page404.component';
import { Page500Component } from './views/pages/page500/page500.component';
import { LoginComponent } from './views/pages/login/login.component';
import { RegisterComponent } from './views/pages/register/register.component';
import { AuthGuard} from './guards/auth.guard';
import { PlantillaCotizacionComponent } from './views/deals/components/quoter/plantilla-cotizacion/plantilla-cotizacion.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'opportunity',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Inicio'
    },
    children: [
      {
        path: 'opportunity',
        loadChildren: () =>
          import('./views/opportunity/opportunity.module').then((m) => m.OpportunityModule), canActivate:[AuthGuard]
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./views/pages/pages.module').then((m) => m.PagesModule)
      },
      {
        path: 'deal/:id',
        loadChildren: () =>
        import('./views/deals/deal/deal.module').then((m) => m.DealModule), canActivate:[AuthGuard]
      },
      {
        path: 'detail/:id',
        loadChildren: () =>
        import('./views/deals/detail/detail.module').then((m) => m.DetailModule), canActivate:[AuthGuard]
      },
      {
        path:'clients',
        loadChildren:() =>
        import('./views/clients/clients.module').then((m) => m.ClientsModule), canActivate:[AuthGuard]
      },{

        path: 'company/:id',
        loadChildren: () =>
        import('./views/company/company/company.module').then((m) => m.CompanyModule), canActivate:[AuthGuard]
      },
      
      {
        path: 'companyDetail/:id',
        loadChildren: () =>
        import('./views/company/company-detail/company-detail.module').then((m) => m.CompanyDetailModule), canActivate:[AuthGuard]
      },
      {
        path: 'companyContact/:id',
        loadChildren: () => 
        import('./views/company/company-contact/company-contact.module').then((m) => m.CompanyContactModule), canActivate:[AuthGuard]
      },
      {
        path:'dealActivity/:id',
        loadChildren: () =>
        import('./views/deals/activity/activity.module').then((m) => m.ActivityModule), canActivate:[AuthGuard]
      },
      {
        path:'dealCompanyDetail/:id',
        loadChildren: () =>
        import('./views/deals/company-detail-deal/company-detail-deal.module').then((m) => m.CompanyDetailDealModule), canActivate:[AuthGuard]
      },
      {
        path:'companyActivity/:id',
        loadChildren: () => 
        import('./views/company/activity/activityC.module').then((m) => m.ActivityModuleC), canActivate:[AuthGuard]
      },
      {
        path: 'ultrasecretuserview',
        loadChildren: () => 
          import('./views/user/user.module').then((m) => m.UserModule), canActivate: [AuthGuard]
      },
      {
        path: 'roles',
        loadChildren: () =>
          import('./views/roles/roles.module').then((m) => m.RolesModule), canActivate: [AuthGuard]
      },
      {
        path:'progressDeal/:id',
        loadChildren:() =>
        import('./views/deals/progress-deal/progress-deal.module').then((m)=> m.ProgressDealModule), canActivate:[AuthGuard]
      },
      {
        path: 'tasks',
        loadChildren:() => 
        import('./views/task-schedule/task-schedule.module').then((m) => m.TaskScheduleModule), canActivate:[AuthGuard]
      },
      {
        path: 'warranties',
        loadChildren:() => 
        import('./views/warranties/warranties.module').then((m) => m.WarrantiesModule), canActivate:[AuthGuard]
      },
    ]
  },
  {
    path: 'plantilla/:id',
    canActivate: [AuthGuard],
    component: PlantillaCotizacionComponent,
  },
  {
    path: '404',
    component: Page404Component,
    data: {
      title: 'Error 404'
    }
  },
  {
    path: '500',
    component: Page500Component,
    data: {
      title: 'Erorr 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Iniciar sesión'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Registrarse'
    }
  },
  {path: '**', redirectTo: 'opportunity'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
