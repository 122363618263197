import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';

import {UserDTO, RegisterUserRequest} from '../models/UserModels';
import { UpdateUserRequest, UpdateUserResponse } from '@app/models/Users/UpdateUser';
import { UpdateUserRolesRequest } from './../models/Users/UpdateUserRolesRequest';
import { EnableDisableUserResponse } from '@app/models/Users/EnableDisableUser';
import { ApiResponse } from '../models/Response';

import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { RegisterUserRequestDTO, RegisterUserResponseDTO } from '@app/models/Users/RegisterUser';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userEndpoint = `${environment.apiUrl}/users`;
  constructor(private httpClient: HttpClient, private _toastService: ToastService) { }

  getUsers(): Observable<UserDTO[]>{
    const endpoint = `${environment.apiUrl}/users`;
    return this.httpClient.get<UserDTO[]>(endpoint);
  }

  getAllUsersNoPermission(): Observable<UserDTO[]> {
    let endpoint = `${this.userEndpoint}/listAll`;
    return this.httpClient.get<UserDTO[]>(endpoint);
  }

  getUserById(userId: string) : Observable<UserDTO> {
    let endpoint = `${this.userEndpoint}/${userId}`;
    return this.httpClient.get<UserDTO>(endpoint);
  }

  public updateUserRoles(request: UpdateUserRolesRequest): Observable<ApiResponse<string>>{
    let endpoint = `${this.userEndpoint}/role`;
    return this.httpClient.put<ApiResponse<string>>(endpoint, request)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error);
      })
    );
  }

  registerNewUser(request: RegisterUserRequestDTO): Observable<ApiResponse<RegisterUserResponseDTO>> {
    const endpoint = `${environment.apiUrl}/users/register`;
    return this.httpClient.post<ApiResponse<RegisterUserResponseDTO>>(endpoint, request)
    .pipe(
      tap({
        next:(response) => this._toastService.showSuccessToast('Registro exitoso', 'Usuario registrado exitosamente'),
        error: (err) => this._toastService.showErrorToast('Registro fallido', err.error.message)
      })
    );
  }

  updateUser = (request: UpdateUserRequest) => {
    const endpoint = `${this.userEndpoint}`;
    return this.httpClient.put<ApiResponse<UpdateUserResponse>>(endpoint, request)
    .pipe(
      tap({
        next: (response) => this._toastService.showSuccessToast('Actualización exitosa', response.message!),
        error: (err) => this._toastService.showErrorToast('Actualización fallida', err.error.message, err.error.errors)
      })
    );
  }

  private async hash(val: string){
    const utf8 = new TextEncoder().encode(val);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  }

  public async hashPassword(pass: string)  {
    const hash = await this.hash(pass);
    return hash;
  }

  public enableUser(userId: string) {
    const endpoint =  `${this.userEndpoint}/${userId}/enable`;
    return this.httpClient.put<ApiResponse<EnableDisableUserResponse>>(endpoint, null)
    .pipe(
      tap({
        next: (res) => this._toastService.showSuccessToast('Usuario habilitado', 'El usuario ha sido habilitado'),
        error: (err) => this._toastService.showErrorToast('Usuario no fue habilitado', err.error.message)
      })
    );
  }

  public disableUser(userId: string) {
    const endpoint =  `${this.userEndpoint}/${userId}/disable`;
    return this.httpClient.put<ApiResponse<EnableDisableUserResponse>>(endpoint, null)    .pipe(
      tap({
        next: (res) => this._toastService.showSuccessToast('Usuario inhabilitado', 'El usuario ha sido inhabilitado'),
        error: (err) => this._toastService.showErrorToast('Usuario no fue inhabilitado', err.error.message)
      })
    );
  }

}
