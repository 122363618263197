import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DealActionToResolute } from '@app/models/DealActions/DealActionToResolute';
import { ResoluteDealActionRequest } from '@app/models/DealActions/DealActionRequests';

@Component({
  selector: 'app-approve-deal-action',
  templateUrl: './approve-deal-action.component.html',
  styleUrls: ['./approve-deal-action.component.scss'],
})
export class ApproveDealActionComponent implements OnInit {
  @ViewChild('autosize') autoSize!: CdkTextareaAutosize;
  public approveActionForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ApproveDealActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DealActionToResolute,
    private _fb: FormBuilder
  ) {
    this.approveActionForm = this._fb.group({
      dealId: [''],
      actionId: [''],
      comment: [],
    });
  }

  ngOnInit(): void {
    this.approveActionForm.patchValue({
      dealId: this.data.dealId,
      actionId: this.data.dealActionId,
    });
  }

  closeDialog = () => {
    this.dialogRef.close();
  };

  submitApproveAction = () => {
    const request: ResoluteDealActionRequest = {
      dealId: this.approveActionForm.get('dealId')?.value,
      dealActionId: this.approveActionForm.get('actionId')?.value,
      comment: this.approveActionForm.get('comment')?.value,
    };
    this.dialogRef.close(request);
  };
}
