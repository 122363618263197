<h3>Editar Accion</h3>
<form [formGroup]="formUpdate" (ngSubmit)="submitUpdate()">
  <mat-dialog-content class="mat-typography" style="max-width: 50vw !important">
    <mat-form-field appearance="outline" class="example-full-width" op>
      <mat-label>Monto</mat-label>
      <input matInput formControlName="amountDetail" *ngIf="show" />
      <input matInput  [disabled]="true" *ngIf="!show" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="example-full-width" op>
      <mat-label>Comision</mat-label>
      <input matInput formControlName="commission" *ngIf="show" />
      <input matInput  [disabled]="true" *ngIf="!show" />

    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button
      [mat-dialog-close]="true"
      type="submit"
      cdkFocusInitial
      mat-raised-button
      color="primary"
      [disabled]="!show"
    >
      Actualizar
    </button>
  </mat-dialog-actions>
</form>
