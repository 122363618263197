import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResoluteDealActionRequest } from '@app/models/DealActions/DealActionRequests';
import { DealActionToResolute } from '@app/models/DealActions/DealActionToResolute';

@Component({
  selector: 'app-reject-action-form',
  templateUrl: './reject-action-form.component.html',
  styleUrls: ['./reject-action-form.component.scss']
})
export class RejectActionFormComponent implements OnInit {
  @ViewChild('autosize') autoSize!: CdkTextareaAutosize;
  public rejectForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<RejectActionFormComponent>, private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DealActionToResolute) { 
    this.rejectForm = this._fb.group({
      dealId: [''],
      actionId: [''],
      comment: []
    });
  }

  ngOnInit(): void {
    this.rejectForm.patchValue({
      dealId: this.data.dealId,
      actionId: this.data.dealActionId
    });
  }

  closeForm = () => {
    this.dialogRef.close();
  }

  submitReject = () => {
    const request: ResoluteDealActionRequest = {
      dealId: this.rejectForm.get('dealId')?.value,
      dealActionId: this.rejectForm.get('actionId')?.value,
      comment: this.rejectForm.get('comment')?.value
    };
    this.dialogRef.close(request);
  }

}
