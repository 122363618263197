import { Component, OnInit, Input, Inject} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpdateDealActionTagsRequest, UpdateDealActionTagsViewModel } from '@app/models/DealActions/UpdateDealActionTag';
import { DealActionsService } from '@app/services/deal-actions.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-update-deal-action-tags-form',
  templateUrl: './update-deal-action-tags-form.component.html',
  styleUrls: ['./update-deal-action-tags-form.component.scss']
})
export class UpdateDealActionTagsFormComponent implements OnInit {

  public updateTagsForm: FormGroup;
  public allTags$: Observable<Array<string>>;

  constructor(public dialogRef: MatDialogRef<UpdateDealActionTagsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public viewModel: UpdateDealActionTagsViewModel,
    private _dealActionService: DealActionsService,
    private _fb: FormBuilder) { 
    this.allTags$ = new Observable<Array<string>>();
    this.updateTagsForm = this._fb.group({
      dealId: [''],
      dealActionId: [''],
      tags: []
    });
  }

  ngOnInit(): void {
    this.fetchAllActionTags();
    this.updateTagsForm.patchValue({
      dealId: this.viewModel.dealId,
      dealActionId: this.viewModel.dealActionId,
      tags: this.viewModel.tags
    });
  }

  closeDialog = () => this.dialogRef.close();

  fetchAllActionTags = () => {
    this.allTags$ = this._dealActionService.getDealActionTags();
  }

  submitUpdate = () => {
    const request: UpdateDealActionTagsRequest = {
      dealId: this.updateTagsForm.get('dealId')?.value,
      dealActionId: this.updateTagsForm.get('dealActionId')?.value,
      tags: this.updateTagsForm.get('tags')?.value
    };
    this._dealActionService.updateDealActionTags(request)
    .subscribe({
      next:(response) => {
        this.dialogRef.close(response.data);
      }
    })
  }

}
