<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <img src="assets/Cohete.png" style="max-width: 20%;" />
      <c-col lg="7" md="9" xl="6">
        <c-card class="mx-4">
          <c-card-body class="p-4">
            <form cForm>
              <h1>Confirma tu cuenta</h1>
              <p class="text-medium-emphasis">Crea tu nueva contraseña</p>
              <c-input-group class="mb-3">
                <span cInputGroupText>@
                </span>
                <input autoComplete="name" cFormControl placeholder="E-mail" />
              </c-input-group>
              <c-input-group class="mb-3">
                <span cInputGroupText>
                  <svg cIcon name="cilLockLocked"></svg>
                </span>
                <input autoComplete="email" cFormControl placeholder="Contraseña actual" />
              </c-input-group>
              <c-input-group class="mb-3">
                <span cInputGroupText>
                  <svg cIcon name="cilLockLocked"></svg>
                </span>
                <input autoComplete="new-password" cFormControl placeholder="Nueva contraseña" type="password" />
              </c-input-group>
              <c-input-group class="mb-4">
                <span cInputGroupText>
                  <svg cIcon name="cilLockLocked"></svg>
                </span>
                <input autoComplete="new-password" cFormControl placeholder="Repetir contraseña" type="password" />
              </c-input-group>
              <div class="d-grid">
                <button class="btnconfirm">Confirmar</button>
              </div>
            </form>
          </c-card-body>
        </c-card>
      </c-col>
    </c-row>
  </c-container>
</div>
