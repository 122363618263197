import { INavData } from '@coreui/angular';
export const navItems: INavData[] = [
  {
    name: 'Manejo de Clientes',
    title: true,
  },
  {
    name: 'Tratos',
    url: '/opportunity',
    iconComponent: { name: 'cilSpeedometer' },
  },
  {
    name: 'Cartera de clientes',
    url: '/clients',
    iconComponent: { name: 'cilTags' },
  },
  {
    name: 'Tareas',
    url: '/tasks',
    iconComponent: { name: 'cilCalendar' },
  },
  {
    name: 'En Evaluación',
    url: '/warranties',
    iconComponent: { name: 'cilSearch' },
  },
  {
    name: 'Usuarios',
    url: '/ultrasecretuserview',
    iconComponent: { name: 'cilPeople' },
  },
]