import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import{  DealActionItem } from './../models/DealActions/DealActionItem';

import { ResoluteDealActionRequest, ResolutePendingDealActionRequest, ResolutionDealActionResponseDTO } from '@app/models/DealActions/DealActionRequests';
import { InsertDealActionRequest } from '@app/models/DealActions/RegisterDealAction';
import { ApiResponse } from '@app/models/Response';
import { ToastService } from './toast.service';
import { RegisterDealActionResponse } from '@app/views/deals/interfaces/dealActions.interface';
import { UpdateDealActionTagsRequest, UpdateDealActionTagsResponse } from '@app/models/DealActions/UpdateDealActionTag';

@Injectable({
  providedIn: 'root'
})
export class DealActionsService {

  private controller: string = 'deals';

  constructor(private _http: HttpClient, private toastService: ToastService) { }

  getDealActionsByDealId(dealId: string): Observable<Array<DealActionItem>> {
    const endpoint = `${environment.apiUrl}/${this.controller}/${dealId}/actions`;
    return this._http.get<Array<DealActionItem>>(endpoint);
  }

  approveDealAction = (request: ResoluteDealActionRequest) => {
    const endpoint = `${environment.apiUrl}/${this.controller}/action/approve`;
    return this._http.put<ApiResponse<ResolutionDealActionResponseDTO>>(endpoint, request)
    .pipe(
      tap({
        next:(res) => {
          this.toastService.showSuccessToast('Aprobación realizada', 'La operación fue aprobada exitosamente')
        },
        error: (err) => {
          this.toastService.showErrorToast('Aprobación fallida', err.error.message);
        }
      })
    );
  }

  rejectDealAction = (request: ResoluteDealActionRequest) => {
    const endpoint = `${environment.apiUrl}/${this.controller}/action/reject`;
    return this._http.put<ApiResponse<ResolutionDealActionResponseDTO>>(endpoint, request)
    .pipe(
      tap({
        next:(res) => {
          this.toastService.showSuccessToast('Rechazo realizado', "La operación fue rechazada exitosamente")
        }, 
        error: (err) => {
          this.toastService.showErrorToast('Rechazo fallido', err.error.message)
        }
      })
    )
  }
  pendingDealAction(request: ResolutePendingDealActionRequest) {
    const endpoint = `${environment.apiUrl}/${this.controller}/action/pendingData`;
    return this._http.put<ApiResponse<ResolutionDealActionResponseDTO>>(endpoint, request)
    .pipe(
      tap({
        next:(res) => {
          this.toastService.showWarningToast('Estado Pendiente realizado', "La operación fue realizada exitosamente")
        }, 
        error: (err) => {
          this.toastService.showErrorToast('Rechazo fallido', err.error.message)
        }
      })
    )
  }

  registerDealAction = (dealId: string, request: InsertDealActionRequest) => {
    const endpoint = `${environment.apiUrl}/${this.controller}/${dealId}/action`;
    return this._http.post<ApiResponse<RegisterDealActionResponse>>(endpoint, request)
    .pipe(
      tap({
        next:(response) => {
          this.toastService.showSuccessToast('Registro de acciones exitoso', 'La operación fue realizada exitosamente');
        },
        error:(err) => {
          this.toastService.showErrorToast('Registro de acciones fallido', err.error.message);
        }
      })
    );
  }

  getDealActionTags = () => {
    const endpoint = `${environment.apiUrl}/${this.controller}/action/tags`;
    return this._http.get<Array<string>>(endpoint);
  }

  updateDealActionTags = (request: UpdateDealActionTagsRequest) => {
    const endpoint = `${environment.apiUrl}/${this.controller}/action/tags`;
    return this._http.put<ApiResponse<UpdateDealActionTagsResponse>>(endpoint, request)
    .pipe(
      tap({
        next: (response) => this.toastService.showSuccessToast('Etiquetas de acción editadas', 'Se ha realizado la operación exitosamente'),
        error: (err) => this.toastService.showErrorToast('Etiquetas de acción no editadas', err.error.message)
      })
    );
  }

  resolveDealActionData(request: ResoluteDealActionRequest) {
    const endpoint = `${environment.apiUrl}/${this.controller}/action/resolutePendingData`;
    return this._http.put<ApiResponse<ResolutionDealActionResponseDTO>>(endpoint, request)
    .pipe(
      tap({
        next: (res) => this.toastService.showSuccessToast('Resolución realizada', 'Se ha resuelto exitosamente esta evaluación'),
        error: (err) => this.toastService.showErrorToast('Resolución fallida', err.error.message)
      })
    );
  }
}
