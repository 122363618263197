<h3>Cambia tu contraseña</h3>
<form [formGroup]="formUpdate" (ngSubmit)="submitUpdate()">
  <mat-dialog-content class="mat-typography" style="max-width: 50vw !important">
    <mat-form-field appearance="outline" class="example-full-width" op>
      <mat-label>Escribe tu nueva contraseña</mat-label>
      <input
        [type]="hidePass ? 'password' : 'text'"
        matInput
        (keyup)="checkPass()"
        formControlName="password"
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="hidePass = !hidePass"
      >
        <mat-icon>{{ hidePass ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="outline" class="example-full-width" op>
      <mat-label>Vuelve a escribir tu contraseña</mat-label>
      <input
        [type]="hidePass ? 'password' : 'text'"
        matInput
        formControlName="passwordCheck"
        (keyup)="checkPass()"
        
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="hidePass = !hidePass"
      >
        <mat-icon>{{ hidePass ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
    </mat-form-field>
    <mat-error *ngIf="showError">
      Las contraseñas no son <strong>iguales</strong>
    </mat-error>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button
      [disabled]="showError"      
      [mat-dialog-close]="true"
      type="submit"
      cdkFocusInitial
      mat-raised-button
      color="primary"
    >
      Actualizar
    </button>
  </mat-dialog-actions>
</form>
