<div clas s="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-left">
      <img src="assets/Robot.png" style="max-width: 35%;padding-top: 100px;" /> 
      <c-col md="6">
        <div class="clearfix"  style="padding-right:200px;">
          <div class="container__text__404">
            <h1 class="float-start display-3 me-4">404</h1>
            <h4 class="pt-3">Oops! No lo encontré</h4>
            <p class="text-medium-emphasis float-start">
              El equipo de TI está trabajando en ello.
            </p>
          </div>

<div class="container__btn404">
  <a href="https://wanak.com/">
    <button class="btn404">Volver al inicio</button>
  </a> 
</div>
       
   
    
        <!-- </div>
        <c-input-group class="input-prepend">
          <span cInputGroupText>
            <svg cIcon name="cilMagnifyingGlass"></svg>
          </span>
          <input cFormControl placeholder="¿Qué buscas?" type="text" />
          <button cButton color="info">Burcar</button>
        </c-input-group>
      </c-col>
    </c-row>
  </c-container>
</div> -->
<!-- Ojalá poner un botón para voler al inicio -->