import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { CompanyService } from '../../services/company.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { CompanyContact } from '../../interfaces/companyContact.interface';
import { Company } from '../../interfaces/company.interface';

@Component({
  selector: 'app-modal-edit-contact',
  templateUrl: './modal-edit-contact.component.html',
  styleUrls: ['./modal-edit-contact.component.scss'],
})
export class ModalEditContactComponent implements OnInit {
  public id = '';
  @Output() closeSuccessModal = new EventEmitter<void>();

  comContact: CompanyContact[] = [];
  companyContact: CompanyContact = {
    clientName: '',
    clientLastName: '',
    phone: '',
    email: '',
    cargo: '',
    clientAddress: '',
    cumpleanios: '',
    rut: '',
  };

  constructor(
    private companySvc: CompanyService,
    private builder: FormBuilder,
    private router: ActivatedRoute,
    public dialogRef: MatDialogRef<ModalEditContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.companyContact = data.userCompany;
  }

  ngOnInit(): void {
    console.log(this.companyContact);
    this.getCompanyInformation();
    this.contactForm.controls.email.disable()
  }
  contactForm = this.builder.group({
    clientName: ['', [Validators.required]],
    clientLastName: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    email: [''],
    cargo: ['', [Validators.required]],
    clientAddress: [''],
    cumplianios: [''],
    rut: this.data.rut,
  });

  public submitUpdateCompany(): void {
    const updateCompanyRequest: CompanyContact = {
      clientName: this.contactForm.controls.clientName.value!,
      clientLastName: this.contactForm.controls.clientLastName.value!,
      phone: this.contactForm.controls.phone.value!,
      email: this.contactForm.controls.email.value!,
      cargo: this.contactForm.controls.cargo.value!,
      clientAddress: this.contactForm.controls.clientAddress.value!,
      cumpleanios: this.contactForm.controls.cumplianios.value!,
      rut: this.data.rut,
    };
    console.log(updateCompanyRequest);

    this.companySvc.editCompanyContact(updateCompanyRequest).subscribe({
      next: (response) => {
        this.closeSuccessModal.emit();
      },
    });
    this.dialogRef.close();
    this.getCompanyInformation();
  }

  private getCompanyInformation() {
    this.contactForm.patchValue({
      rut: this.companyContact.rut,
      clientName: this.companyContact.clientName,
      clientLastName: this.companyContact.clientLastName,
      clientAddress: this.companyContact.clientAddress,
      phone: this.companyContact.phone,
      email: this.companyContact.email,
      cargo: this.companyContact.cargo
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
