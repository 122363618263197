import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private cookieService: CookieService, private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const cookie = this.cookieService.check('token');
      if(!cookie){
        this.router.navigate(['login']);
        return false;
      }
      const token = this.cookieService.get('token');
      const jwtHelper = new JwtHelperService();
      if(jwtHelper.isTokenExpired(token)){
        this.router.navigate(['login']);
        return false;
      }

    return true;
  }
  
}
