import { Component, ViewChild, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {
  faCheckCircle,
  faAddressBook,
  faTags,
  faTimesCircle,
  faExclamationTriangle,
  faPen,
  faDollar,
} from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { DealsService } from '../deals/services/deals.service';
import { DealsActions } from '../deals/interfaces/dealActions.interface';
import { ActionBoardService } from './service/action-board.service';
import { FiltersActionsName } from './interfaces/actionDeal.interface';
import { Router } from '@angular/router';
import { DealActionToResolute } from '@app/models/DealActions/DealActionToResolute';
import { CdkContextMenuTrigger } from '@angular/cdk/menu';
import { ApproveDealActionComponent } from '@app/features/deal-actions/components/approve-deal-action/approve-deal-action.component';
import { DealActionsService } from '@app/services/deal-actions.service';
import { Observable, filter, mergeMap, finalize } from 'rxjs';
import { RejectActionFormComponent } from '@app/features/deal-actions/components/reject-action-form/reject-action-form.component';
import { MatSort, MatSortable } from '@angular/material/sort';
import { PendingActionFormComponent } from '@app/features/deal-actions/components/pending-action-form/pending-action-form.component';
import { AuthService } from '@app/services/auth/auth.service';
import { UpdateDealActionTagsResponse, UpdateDealActionTagsViewModel } from '@app/models/DealActions/UpdateDealActionTag';
import { UpdateDealActionTagsFormComponent } from '@app/features/deal-actions/components/update-deal-action-tags-form/update-deal-action-tags-form.component';
import { MatTableDataSource } from '@angular/material/table';
import { EditContentActionComponent } from '../deals/modal/edit-content-action/edit-content-action.component';
import { ModalCommentComponent } from './components/modals/modal-comment/modal-comment.component';

@Component({
  selector: 'app-warranties',
  templateUrl: './warranties.component.html',
  styleUrls: ['./warranties.component.scss'],
})
export class WarrantiesComponent implements OnInit, AfterViewInit {
  @ViewChild('contextmenu', { static: false }) public contextmenu!: ElementRef;
  @ViewChild(CdkContextMenuTrigger) menu!: CdkContextMenuTrigger;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public source!: MatTableDataSource<DealsActions>;
  public icons = {
    faCheckCircle,
    faAddressBook,
    faTags,
    faTimesCircle,
    faPen,
    faExclamationTriangle,
    faDollar,
  };
  public actions: DealsActions[] = [];
  public allTags$: Observable<Array<string>>;
  public accion: number = 0;
  public pageSize = 5;
  public pageSizeOptions: number[] = [5, 10, 15, 20, 50];
  public showSpinner: boolean = false;
  contextMenuPosition = { x: '0px', y: '0px' };
  menuTopLeftPosition = { x: '0', y: '0' };

  displayedColumns: string[] = [
    'isActiveClient',
    'actionName',
    'companyName',
    'companyRut',
    'buyerName',
    'warrantyType',
    'amount',
    'commission',
    'tags',
    'dealExecutive',
    'creationDate',
    'comment',
    'hasActiveWarranties',
  ];
  constructor(
    public dialog: MatDialog,
    private actionBoard: ActionBoardService,
    private dealService: DealsService,
    private _dealActionService: DealActionsService,
    private router: Router,
    private auth: AuthService
  ) {
    this.allTags$ = new Observable<Array<string>>();
  }

  ngOnInit(): void {
    this.source = new MatTableDataSource();
    this.getAllDeals();
    this.shouldShowNavItem();
    this.getAllTags();
  }

  ngAfterViewInit() {
    this.source.paginator = this.paginator;
    this.source.sort = this.sort;
  }

  getAllTags = () =>
    (this.allTags$ = this._dealActionService.getDealActionTags());

  getAllDeals(value: number = 0) {
    this.accion = value;
    this.showSpinner = true;

    this.dealService
      .getDealsDetailsActions(value)
      .pipe(finalize(() => (this.showSpinner = false)))
      .subscribe((e) => {
        this.actions = e;
        this.setDataSource(e);
      });
  }

  receiveAction(event: number) {
    this.getAllDeals(event);
  }

  private setDataSource(dataSource: Array<DealsActions>) {
    this.source = new MatTableDataSource<DealsActions>(dataSource);
    this.source.paginator = this.paginator;
    this.source.sort = this.sort;
  }

  receiveFilter(event: FiltersActionsName) {
    const filter = this.actionBoard.filterNameRutDealsActions(
      this.actions,
      event
    );
    this.setDataSource(filter);
  }
  public dealDialogOpen(args: string): void {
    this.router.navigate(['deal/' + args]);
  }

  receiveFilterDate(event: any) {
    const filterDate = this.actionBoard.filterNameRutDealsActions(
      this.actions,
      event
    );
    this.setDataSource(filterDate);
  }

  openApprovingActionForm = (action: DealsActions) => {
    const actionToApprove: DealActionToResolute = {
      dealId: action.dealId,
      dealActionId: action.dealActionId,
    };
    const approveActionDialogRef = this.dialog.open(
      ApproveDealActionComponent,
      {
        data: actionToApprove,
        width: '40%',
      }
    );

    const closedApproveDialog$ = approveActionDialogRef.afterClosed().pipe(
      filter((request) => request !== undefined),
      mergeMap((request) => this._dealActionService.approveDealAction(request))
    );
    closedApproveDialog$.subscribe((res) => {
      this.getAllDeals();
    });
  };

  openPendingActionForm(action: DealsActions) {
    const actionToPending: DealActionToResolute = {
      dealId: action.dealId,
      dealActionId: action.dealActionId,
    };

    const pendingActionDialogRef = this.dialog.open(
      PendingActionFormComponent,
      {
        width: '40%',
        data: actionToPending,
      }
    );
    const closedApproveDialog$ = pendingActionDialogRef
      .afterClosed()
      .pipe(filter((response) => response !== undefined));
    closedApproveDialog$.subscribe((res) => {
      this.getAllDeals();
    });
  }

  openRejectActionForm = (action: DealsActions) => {
    const actionToReject: DealActionToResolute = {
      dealId: action.dealId,
      dealActionId: action.dealActionId,
    };

    const rejectActionDialogRef = this.dialog.open(RejectActionFormComponent, {
      data: actionToReject,
      width: '40%',
    });

    const closedRejectActionDialog$ = rejectActionDialogRef.afterClosed().pipe(
      filter((request) => request !== undefined),
      mergeMap((request) => this._dealActionService.rejectDealAction(request))
    );
    closedRejectActionDialog$.subscribe((res) => {
      this.getAllDeals();
    });
  };

  updateDealActionTags = (action: DealsActions) => {
    const viewModel: UpdateDealActionTagsViewModel = {
      dealId: action.dealId,
      dealActionId: action.dealActionId,
      tags: action.tags,
    };

    const updateActionTagsDialog = this.dialog.open(
      UpdateDealActionTagsFormComponent,
      {
        data: viewModel,
        width: '40%',
      }
    );

    const closedUpdateTagsDialog$ = updateActionTagsDialog
      .afterClosed()
      .pipe(
        filter(
          (response: UpdateDealActionTagsResponse) => response !== undefined
        )
      );
    closedUpdateTagsDialog$.subscribe((res) => this.getAllDeals());
  };

  openEditAction(value: any): void {
    this.dialog.open(EditContentActionComponent, {
      data: value,
      width: '40%',
    });
  }

  shouldShowNavItem(): void {
    let user = this.auth.hasPolicy('AprobarRechazarAccionTrato');
    if (user == false) {
      this.accion = 4;
    }
  }

  openDialog(value: string) {
    const dialogRef = this.dialog.open(ModalCommentComponent, {
      data: value,
      width: '60%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
