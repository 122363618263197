import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../../services/auth/auth.service';
import { LoginResponse } from 'src/app/models/AuthenticateModels';
import { Router } from '@angular/router';
import { ApiResponse } from '../../../models/Response';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [CookieService],
})
export class LoginComponent {
  public loginForm: FormGroup;
  public hide: boolean = true;
  public hideButton: boolean = true;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router,
    private toast: ToastService
  ) {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  async login() {
    const val = this.loginForm.value;
    this.hideButton = false;

    (await this.authService.login(val.email, val.password)).subscribe({
      next: (response) => {
        this.router.navigate(['opportunity']);
      },
      error: (e: ApiResponse<LoginResponse>) => {
        this.hideButton = true;
        this.toast.showErrorToast('Inicio de Sesión invalido', e.message!);
      },
    });
  }
}
