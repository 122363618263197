<h3>Rechazar acción</h3>
<div>
    <form [formGroup]="rejectForm" (ngSubmit)="submitReject()">
        <mat-form-field class="w-100">
            <mat-label>Comentario</mat-label>
            <textarea matInput formControlName="comment"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="5">
            </textarea>
        </mat-form-field>
        <div class="d-flex justify-content-end gap-1">
            <button (click)="closeForm()" type="button" mat-raised-button>Cancelar</button>
            <button type="submit" mat-raised-button color="primary">Confirmar</button>
        </div>
    </form>
</div>