import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginRequest, LoginResponse } from '../../models/AuthenticateModels';
import { ApiResponse } from 'src/app/models/Response';
import {
  Observable,
  of,
  Subject,
  ReplaySubject,
  shareReplay,
  tap,
  catchError,
  throwError,
} from 'rxjs';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public cacheTable = {
    queryP: '',
    date: [],
  };

  constructor(
    private httpClient: HttpClient,
    private cookiesService: CookieService
  ) {}

  async login(
    email: string,
    password: string
  ): Promise<Observable<ApiResponse<LoginResponse>>> {
    const hashedPass = await this.hash(password);
    let loginRequest: LoginRequest = {
      email: email,
      password: hashedPass,
    };

    let endpoint = `${environment.apiUrl}/users/auth`;
    return this.httpClient
      .post<ApiResponse<LoginResponse>>(endpoint, loginRequest)
      .pipe(
        tap((response) => {
          this.setToken(response.data);
        }),
        catchError((error) => {
          return throwError(() => error.error);
        })
      );
  }

  logout(): void {
    this.cookiesService.delete('token');
  }

  private setToken(loginResponse: LoginResponse) {
    const cookieOption: CookieOptions = {
      sameSite: 'Strict',
      secure: true,
    };
    this.cookiesService.set('token', loginResponse.token, cookieOption);
  }

  private async hash(val: string) {
    const utf8 = new TextEncoder().encode(val);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  }

  public getUserId(): string {
    let tkn = this.cookiesService.get('token');
    const jwtHelper = new JwtHelperService();
    let decode = jwtHelper.decodeToken(tkn);
    return decode.id;
  }

  public hasPolicy(policyToFind: string): boolean {
    let tkn = this.cookiesService.get('token');
    const jwtHelper = new JwtHelperService();
    let decode = jwtHelper.decodeToken(tkn);
    // console.log(decode)    
    const policies: Array<string> = decode.policies;    
    let hasPolicy = policies.find((policy) => policy === policyToFind);
    return hasPolicy !== undefined;
  }

  public getRole(){
    let tkn = this.cookiesService.get('token');
    const jwtHelper = new JwtHelperService();
    let decode = jwtHelper.decodeToken(tkn);
    const policies: Array<string> = decode.policies; 
    console.log(policies);

  }

  public getJwt(): string {
    let jwt = this.cookiesService.get('token');
    return jwt || '';
  }
}
