import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalChangePassComponent } from '@app/containers/modal-change-pass/modal-change-pass.component';
import { HeaderComponent } from '@coreui/angular';
import { IconSetService } from '@coreui/icons-angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { faChevronLeft, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { DealsService } from '@app/views/deals/services/deals.service';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent {
  @Input() sidebarId: string = 'sidebar';
  faChevronLeft = faChevronLeft;
  faLockOpen = faLockOpen;
  public newMessages = new Array(4);
  public newTasks = new Array(5);
  public newNotifications = new Array(5);
  public utm!: number;
  public uf!: number;
  public usd!:  number;

  constructor(    
    private authService: AuthService,
    private router: Router,
    public iconSet: IconSetService,
    public dialog: MatDialog,
    private dealSvc: DealsService
  ) {
    super();     
  }

  ngOnInit(): void {
    this.getIndicators();
  }

  openDialog() {
    const dialogRef = this.dialog.open(ModalChangePassComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  public getIndicators() {
    this.dealSvc.getIndicadoresEconomicos().subscribe((res) => {
      this.uf = res['UF'];
      this.usd = res['USD'];  
      this.utm = res['UTM'];  
    });
  }

}
