<!--<div [ngClass]="{ difuminar_Fondo: loadingCotizacion }" class="contenedor-principal">
    <div #htmlData id="htmlData">
        <mat-card id="borde1" appearance="outlined" class="borde_card">
            <mat-card-content class="card-content">
                <div class="row">
                    <div id="htmlLogo" class="logo col-md-9">
                        <img 
                            width="158px" 
                            height="48px" 
                            style="opacity: 0.6; margin-bottom: 35px;" 
                            src="../../../../../../assets/dark-logo.png"
                        />
                    </div>
                    <div class="col-md-3 opacity" style="font-weight: bold;">
                        <span>Codigo: {{ cotizacion?.code }}</span><br>
                        <span>Emitida: {{ cotizacion?.createdAt | date: "dd/MM/yyyy" }}</span>
                    </div>
                </div>

                <div class="info_User">
                    <h1 id="name" class="titulo">Cotización</h1>
                    <div class="row">
                        <div class="col-md-5">
                            <h3><span class="text-title">Cliente:</span> {{ cotizacion?.clientName }}</h3>
                            <h3><span class="text-title">Rut:</span> {{ cotizacion?.clientRut ?? '0' | rut }}</h3>
                            <h3><span class="text-title">Vigencia:</span> {{ cotizacion?.dueDate | date: "dd/MM/yyyy" }}</h3>
                        </div>
                        <div class="col-md-5">
                            <h3><span class="text-title">Ejecutivo:</span> {{ cotizacion?.executiveName }}</h3>
                            <h3><span class="text-title">Email:</span> {{ cotizacion?.executiveEmail }}</h3>
                            <h3><span class="text-title">Telefono:</span> +569-</h3>
                        </div>
                    </div>
                </div>

                <div class="contenedor_List">
                    <h2>Productos</h2>
                    <hr />
                    <table mat-table [dataSource]="dataSource" matSort matSortDirection="desc">
                        <ng-container matColumnDef="documentType">
                            <th mat-header-cell *matHeaderCellDef class="custom-header" style="color: #003c8a;">Descripción</th>
                            <td mat-cell *matCellDef="let element">{{ descripcionProducto(element.documentType, element.warrantyType, element.startDate, element.endDate, element.amount, element.currency) }}</td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: bold; color: #003c8a;"> Total a Pagar </td>
                        </ng-container>

                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef class="custom-header" style="min-width: 180px; color: #003c8a;">Valor</th>
                            <td mat-cell *matCellDef="let element"> {{ cotizacion?.currency }} {{ element.price | number:'1.0-2' }} </td>
                            <td mat-footer-cell *matFooterCellDef style="font-weight: bold; color: #003c8a;"> &nbsp;&nbsp;&nbsp;&nbsp;{{ getTotalCost() }} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedCols"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedCols"></tr>
                        <tr mat-footer-row *matFooterRowDef="displayedCols"></tr>
                    </table>
                </div>
            </mat-card-content>

            <div class="footer-text">
                <span>"INGE S.A.G.R. se reserva el derecho de emitir sus certificados de fianza hasta tener plena certeza de estar dando íntegro cumplimiento de lo dispuesto en la Ley N° 20.179, de las demás normativas legales y regulatorias aplicables, y de sus procedimientos internos de emisión y evaluación de riesgo. El pago por parte del cliente de las garantías, no excepciona este derecho.”</span>
            </div>
        </mat-card>
    </div>
</div>-->

<div id="htmlData" #htmlData>

    <!DOCTYPE html>
    <html lang="es" >
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Cotización</title>
        </head>
        <body>
            <div style="    
                display: flex;
                justify-content: center; 
                max-height: 100vh;
                margin: 0;
                padding: 20px;
                font-family: Arial;"
                >
                <div id="htmlData">
                    <div id="borde1" appearance="outlined" style="    
                        display: flex;
                        flex-direction: column; 
                        justify-content: space-between; 
                        width: 794px;
                        height: 960px;
                        border: 1px solid #003c8a;
                        padding: 20px; 
                        box-sizing: border-box;">
                        <div style="  
                            flex: 1; 
                            display: flex;
                            flex-direction: column;
                            gap: 10px;">
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <div>
                                    <img 
                                        width="158px" 
                                        height="48px" 
                                        style="opacity: 0.6; margin-bottom: 35px;" 
                                        src="https://app.inge.cl/assets/images/logos/dark-logo.png"
                                    />
                                </div>
                                <div style="font-weight: bold; font-weight: bold;">
                                    <span>Codigo: {{ cotizacion?.code }} </span><br>
                                    <span>Emitida: {{ cotizacion?.createdAt | date: "dd/MM/yyyy" }} </span>
                                </div>
                            </div>

                            <div style="margin-bottom: 20px;">
                                <h1 id="name" style="
                                    color: #003c8a;
                                    text-align: center;
                                    font-weight: bolder;
                                    margin-bottom: 50px;">Cotización</h1>
                                <div style="display: flex; justify-content: space-between;">
                                    <div style="flex: 1; padding-right: 20px;">
                                        <h3><span style="font-weight: 500; font-size: 1em">Cliente:</span> {{ cotizacion?.clientName }} </h3>
                                        <h3><span style="font-weight: 500; font-size: 1em">Rut:</span> {{ cotizacion?.clientRut ?? '0' | rut }} </h3>
                                        <h3><span style="font-weight: 500; font-size: 1em">Vigencia:</span> {{ cotizacion?.dueDate | date: "dd/MM/yyyy" }} </h3>
                                    </div>
                                    <div style="flex: 1;">
                                        <h3><span style="font-weight: 500; font-size: 1em">Ejecutivo:</span> {{ cotizacion?.executiveName }} </h3>
                                        <h3><span style="font-weight: 500; font-size: 1em">Email:</span> {{ cotizacion?.executiveEmail }} </h3>
                                        <h3><span style="font-weight: 500; font-size: 1em">Telefono:</span> +56{{ cotizacion?.executivePhone }}</h3>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h2>Productos</h2>
                                <hr />
                                <table style="border-collapse: collapse; width: 100%;">
                                    <thead>
                                        <tr>
                                            <th class="custom-header" style="color: #003c8a; font-size: 1.1em; font-weight: bolder; border: 1px solid #ddd; padding: 8px;">
                                                Descripción
                                            </th>
                                            <th *ngIf="discount" class="custom-header" style="color: #003c8a; font-size: 1.1em; font-weight: bolder; border: 1px solid #ddd; padding: 8px;">
                                                Descuento
                                            </th>
                                            <th class="custom-header" style="min-width: 180px; color: #003c8a; font-size: 1.1em; font-weight: bolder; border: 1px solid #ddd; padding: 8px;">
                                                Precio
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of cotizacion?.items" style="border-top: 1px solid #ddd;">
                                            <td style="border: 1px solid #ddd; padding: 8px;">
                                                {{ descripcionProducto(item.documentType, item.warrantyType, item.startDate, item.endDate, item.amount, item.currency) }}
                                            </td>
                                            <td *ngIf="discount" style="border: 1px solid #ddd; padding: 8px;">
                                                {{ item?.itemPriceCurrency }} {{ item.discount | number:'1.0-2' }}
                                            </td>
                                            <td *ngIf="discount" style="border: 1px solid #ddd; padding: 8px;">
                                                {{ item?.itemPriceCurrency }} {{ item.price | number:'1.0-2' }}
                                            </td>

                                            <td *ngIf="!discount" style="border: 1px solid #ddd; padding: 8px;">
                                                {{ item?.itemPriceCurrency }} {{ (item.price + (item.discount*-1)) | number:'1.0-2' }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="discount">
                                            <td style="font-weight: bold; color: #003c8a; border-top: 1px solid #ddd; padding: 8px;">
                                                Total
                                            </td>
                                            <td style="font-weight: bold; color: #003c8a; border-top: 1px solid #ddd; padding: 8px;">
                                                {{ getDiscontProducts() }}
                                            </td>
                                            <td style="font-weight: bold; color: #003c8a; border-top: 1px solid #ddd; padding: 8px;">
                                                {{ getCostProducts() }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: bold; color: #003c8a; border-top: 1px solid #ddd; padding: 8px;">
                                                Total a Pagar
                                            </td>
                                            <td *ngIf="discount" style="font-weight: bold; color: #003c8a; border-top: 1px solid #ddd; padding: 8px;">

                                            </td>
                                            <td style="font-weight: bold; color: #003c8a; border-top: 1px solid #ddd; padding: 8px;">
                                                {{ getTotalCost() }}
                                            </td>
                                        </tr>
                                    </tfoot>
                                    
                                </table>
                                
                            </div>
                        </div>

                        <div style="
                            text-align: center;
                            margin-top: 10px;
                            color: #676767;
                            font-size: 0.9em;
                            padding: 3px;">
                            <span>"INGE S.A.G.R. se reserva el derecho de emitir sus certificados de fianza hasta tener plena certeza de estar dando íntegro cumplimiento de lo dispuesto en la Ley N° 20.179, de las demás normativas legales y regulatorias aplicables, y de sus procedimientos internos de emisión y evaluación de riesgo. El pago por parte del cliente de las garantías, no excepciona este derecho.”</span>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    </html>

</div>