<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <img class= sv src="assets/Salvavidas.png" style="max-width: 35%;" />  <c-container>
    <c-row class="justify-content-center" style="padding-right: 180px;">
      <c-col md="6">
        <span class="clearfix">
          <h1 class="float-start display-3 me-4">500</h1>
          <h4 class="pt-3">¡Houston, tenemos un problema!</h4>
          <p class="text-medium-emphasis float-start">
            Lo que buscas no se encuentra disponible temporalmente.
          </p>
        </span>
        <div class="container__btn500">
          <a href="https://wanak.com/">
            <button class="btn500">Volver al inicio</button> 
          </a> 
        </div>
        <!-- <c-input-group class="input-prepend">
          <span cInputGroupText>
            <svg cIcon name="cilMagnifyingGlass"></svg>
          </span>
          <input cFormControl placeholder="What are you looking for?" type="text" />
          <button cButton color="info">Search</button>
        </c-input-group> -->
      </c-col>
    </c-row>
  </c-container>
</div>
