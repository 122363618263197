<div>
    <form [formGroup]="insertActionForm"
    >
        <mat-form-field
            appearance="outline" class="w-100 my-3">
            <mat-label>Acción</mat-label>
            <mat-select (selectionChange)="changeSelectedAction($event)"
                formControlName="actionId">
                <mat-option *ngFor="let action of dealActions"
                [value]="action.actionId">
                    {{action.actionName}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="dealSubOptions.length > 0" appearance="outline" class="w-100 mb-3">
            <mat-label>Acciones</mat-label>
            <mat-select multiple
                formControlName="subActions">
                <mat-option *ngFor="let subAction of dealSubOptions"
                [value]="subAction.subActionId">
                    {{subAction.subActionName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="container_amounts">
            <mat-form-field appearance="outline" class="container_amount">
                <mat-label>Monto Garantía</mat-label>
                <input type="text" mask="separator" thousandSeparator="." matInput formControlName="dealAmount">
            </mat-form-field>
            <mat-form-field appearance="outline" class="container_amount">
                <mat-label>Monto Comisión</mat-label>
                <input type="text" mask="separator" thousandSeparator="." matInput formControlName="commission">
            </mat-form-field>
        </div>
        <div class="container_amounts">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Comentario</mat-label>
                <textarea matInput formControlName="comment"></textarea>
            </mat-form-field>
        </div>
        <div class="d-flex justify-content-end">
            <button type="button" (click)="submitActions()" mat-raised-button color="primary"
            [disabled]="!insertActionForm.valid">
                Registrar
            </button>
        </div>
    </form>
</div>
