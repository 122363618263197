import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WarrantiesComponent } from './warranties.component';
const routes: Routes = [
  {
      path: '', 
      component: WarrantiesComponent,
      data: {
          title: `Evaluation`
      }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WarrantiesRoutingModule { }
