<!--<c-footer>-->
  <div>
    <a href="https://wanak.com/" target="_blank">Wanak</a>
    <span> &copy; 2022 Una empresa InGe!</span>
  </div>
  <div class="ms-auto">
    Powered by
    <a href="https://inge.cl/" target="_blank">
      <span> InGe!</span>
    </a>
  </div>
<!--</c-footer>-->
