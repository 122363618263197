import { Component } from '@angular/core';

import { navItems } from './_nav';
import { AuthService } from '@app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent {
  public navItems = navItems.filter((item) => this.shouldShowNavItem(item));
  public view: string = 'VerVistaUsuarios';
  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  constructor(private auth: AuthService) {}

  shouldShowNavItem(item: any): boolean {
    if (item.name === 'Usuarios') {
      // Verifica si el usuario tiene el permiso 'AccederVistaUsuarios'
      return this.auth.hasPolicy('AccederVistaUsuarios');
    }
    return true; // Mostrar los otros elementos del menú sin cambios
  }
}
