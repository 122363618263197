import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  ActionsOptions,
  SubActionsOptions,
} from '@app/views/deals/interfaces/dealActions.interface';
import { DealsService } from '@app/views/deals/services/deals.service';
import {
  DealActionItemToInsert,
  InsertDealActionRequest,
} from '@app/models/DealActions/RegisterDealAction';
import { DealActionsService } from '@app/services/deal-actions.service';

@Component({
  selector: 'app-register-action-form',
  templateUrl: './register-action-form.component.html',
  styleUrls: ['./register-action-form.component.scss'],
})
export class RegisterActionFormComponent implements OnInit {
  @Input()
  public dealId: string;

  @Input() dealAmount!: number | null;
  @Input() commission!: number | null;

  @Output()
  public notifySuccess = new EventEmitter<void>();

  public dealActions: Array<ActionsOptions>;
  public dealSubOptions: Array<SubActionsOptions>;
  public insertActionForm: FormGroup;

  constructor(
    private _dealService: DealsService,
    private _dealActionService: DealActionsService,
    private _fb: FormBuilder,
  ) {
    this.dealId = '';
    this.dealSubOptions = new Array<SubActionsOptions>();
    this.dealActions = new Array<ActionsOptions>();
    this.insertActionForm = this._fb.group({
      actionId: [null, Validators.required],
      subActions: [[]],
      dealAmount: [null],
      commission: [''],
      comment: ['']
    });
  }

  ngOnInit(): void {
    this.setFormData()
    this.fetchDealActionOptions();
  }

  setFormData() {
    this.insertActionForm.patchValue({
      dealAmount: this.dealAmount,
      commission: this.commission,
    });
  }

  fetchDealActionOptions() {
    this._dealService
      .getActionsDetail()
      .subscribe((res) => (this.dealActions = res));
  };

  changeSelectedAction(event: any) {
    const selectedAction = event.value;
    const subOptions = this.dealActions.find(
      (a) => a.actionId == selectedAction
    )?.subActions;
    //Enable the subactions form control if there are options to select
    if (subOptions !== undefined && subOptions.length > 0) {
      this.enableSubActionsValidation();
    } else {
      this.disableSubActionsValidation();
    }

    this.dealSubOptions = subOptions || new Array<SubActionsOptions>();
    this.insertActionForm.patchValue({
      subActions: [],
    });
  };

  enableSubActionsValidation = () => this.insertActionForm.get('subActions')?.addValidators(Validators.required);
  disableSubActionsValidation = () => this.insertActionForm.get('subActions')?.clearValidators();

  submitActions() {
    let actionsToInsert = new Array<DealActionItemToInsert>();

    const selectedActionId: string = this.insertActionForm.get('actionId')?.value;
    const dealAmount: number | null = this.insertActionForm.get('dealAmount')?.value;
    const commission: number | null = this.insertActionForm.get('commission')?.value;
    const comment: string = this.insertActionForm.get('comment')?.value;
    const selectedSubActions: Array<string> = this.insertActionForm.get('subActions')?.value;

    if (selectedSubActions.length > 0) {
      selectedSubActions.forEach((subActionId) => {
        actionsToInsert.push({
          selectedActionId: selectedActionId,
          selectedOptionId: subActionId,
        });
      });
    } else {
      actionsToInsert.push({
        selectedActionId: selectedActionId,
        selectedOptionId: null,
      });
    }
    const request: InsertDealActionRequest = {
      dealActions: actionsToInsert,
      dealAmount: dealAmount,
      commission: commission,
      comment: comment
    };    

    this._dealActionService.registerDealAction(this.dealId, request).subscribe({
      next: (res) => {
        this.resetForm();
        this.notifySuccess.emit();
      },
    });
  };

  resetForm() {
    this.disableSubActionsValidation();
    this.insertActionForm.patchValue({
      actionId: '',
      subActions: [],
      comment: ''
    });
    this.insertActionForm.markAsUntouched();
    this.insertActionForm.markAsPristine();
    this.dealSubOptions = new Array<SubActionsOptions>();
  };
}
