import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import {
  DealForm,
  DealItemBoardDTO,
  NewDealForm,
  Opportunity,
} from '../models/OpportunityModels';
import { UpdateDealStageResponse } from '../models/Deals/UpdateDealStageResponse';

import { ApiResponse } from '../models/Response';
import { environment } from './../../environments/environment';
import {
  DataSourceChangedEventArgs,
  DataStateChangeEventArgs,
} from '@syncfusion/ej2-angular-kanban';
import { catchError, map } from 'rxjs/operators';
import { FilterParameter } from '../models/QueryFilterModel'
import { DealsQuery } from '../views/opportunity/DealsQueryModel'
import { Change } from '../models/FunnelModels'
import { HeaderDealBoardFilter } from '../models/Deals/HeaderDealBoardFilter';


@Injectable ({
    providedIn: 'root'
})
export class opportunityService extends Subject<DataStateChangeEventArgs> {
  
  constructor(private http: HttpClient) {
    super();
  }

  getOpportunity() {
    return this.http.get<Opportunity[]>('https://localhost:7291/Opportunity');
  }

  postNewDeal(form: NewDealForm): Observable<ApiResponse<string>> {
    let urlEndpoint = environment.apiUrl;
    return this.http.post<ApiResponse<string>>(`${urlEndpoint}/deals`, form);
  }

  getDealsByFunnel(funnelId: string): Observable<DealItemBoardDTO[]> {
    let apiEndpoint = `${environment.apiUrl}/deals/funnel/${funnelId}`;
    return this.http.get<DealItemBoardDTO[]>(apiEndpoint);
  }

  //Get the deals by the funnel for the kanban


  
    //Get the deals by the funnel for the kanban
    protected getDealsBySelectedFunnel(state: DataStateChangeEventArgs, dealFilter: HeaderDealBoardFilter)
    : Observable<DataStateChangeEventArgs>
    {
        let queryString : string = '';
        const executiveFilter = `ExecutiveOrGroup=${dealFilter?.executiveGroup}`;
        const funnelFilter = `&Funnel=${dealFilter.funnelId}`;
        const searchFilter = `&Search=${dealFilter.searchNameRut}`;

        let clientActiveFilter = '';
        if(dealFilter.activeClient !== null) {
            clientActiveFilter = `&ClientActive=${dealFilter.activeClient}`;
        }

        let isPriorityFilter = '';
        if(dealFilter.isPriority !== null) {
            isPriorityFilter = `&Priority=${dealFilter.isPriority}`;
        }

        let availableLineFilter = '';
        if(dealFilter.hasAvailableLine !== null) {
            `&AvailableLine=${dealFilter.hasAvailableLine}`;

        }

        let hasContactFilter = '';
        if(dealFilter.hasContact !== null) {
            hasContactFilter = `&HasContact=${dealFilter.hasContact}`;
        }

        let isStallingFilter = '';
        if(dealFilter.isStalling !== null) {
            isStallingFilter = `&IsStalling=${dealFilter.isStalling}`;
        }

        let createdFromFilter = '';
        if(dealFilter.createdFrom !== null) {
            createdFromFilter = `&CreatedFrom=${dealFilter.createdFrom}`;
        }

        let createdUntilFilter = '';
        if(dealFilter.createdUntil !== null) {
            createdUntilFilter = `&CreatedUntil=${dealFilter.createdUntil}`;
        }

        let sortingField = ''
        if(dealFilter.sortingField){
            sortingField = `&SortField=${dealFilter.sortingField}`;
        }
        const sortOrder = `&SortOrder=${dealFilter.sortingDirection}`;

        queryString = executiveFilter + funnelFilter + searchFilter 
        + hasContactFilter + isStallingFilter + 
        clientActiveFilter + isPriorityFilter + availableLineFilter
        + createdFromFilter + createdUntilFilter + sortingField+ sortOrder;

        return this.http.get(`${environment.apiUrl}/deals?${queryString}`)
        .pipe(
            map((response: any) => (<any> {result: response}))
        ,catchError(
            (error: HttpErrorResponse) => {
                return throwError(() =>  error.error)
            }
        ));        
    }

    public execute(state: any, dealFilter: HeaderDealBoardFilter) {
        this.getDealsBySelectedFunnel(state, dealFilter)
        .subscribe({
            next: (x) => {super.next(x)},
            error: (err) => {
                super.next(err);
            }
        });
    }

    updateDealStage(state: DataSourceChangedEventArgs): Observable<any>{
        let apiEndpoint= `${environment.apiUrl}/deals/stage`;
        const dealId = state.changedRecords![0]['id']
        const stage = state.changedRecords![0]['stageId']
        let request = {dealId : dealId, stageId : stage};
        return this.http.put(apiEndpoint, request)
        .pipe(
            catchError(
                (error: HttpErrorResponse)=>{
                    return throwError(() => error.error)        
                }
            )
        );
    }

    updateDealProgress(form:Change):Observable<ApiResponse<UpdateDealStageResponse>>{
        
        let apiEndpoint= `${environment.apiUrl}/deals/stage`;
        return this.http.put<ApiResponse<UpdateDealStageResponse>>(apiEndpoint,form);
    }
}