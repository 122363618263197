import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { fromEvent, take } from 'rxjs';
import { Toast } from 'bootstrap';
import { EventTypes } from 'src/app/models/ToastModels';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  @Output() disposeEvent = new EventEmitter();
  @ViewChild('toastElement', { static: true })
  toastEl!: ElementRef;
  @Input()
  type!: EventTypes;
  @Input()
  title!: string;
  @Input()
  message!: string;
  @Input()
  errors?: Array<string>;
  toast!: Toast;
  constructor() { }

  ngOnInit(): void {
    this.show();
  }

  show() {
    this.toast = new Toast(
      this.toastEl.nativeElement,
      this.type === EventTypes.Error ? { autohide: true} : { delay: 5000},
    );

    fromEvent(this.toastEl.nativeElement, 'hidden.bs.toast')
      .pipe(take(1))
      .subscribe(() => this.hide());

    this.toast.show();
  }

  hide() {
    this.toast.dispose();
    this.disposeEvent.emit();
  }

}