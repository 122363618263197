import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  ActionStatus,
  FiltersActionsName,
} from '../../interfaces/actionDeal.interface';
import { DealsService } from '@app/views/deals/services/deals.service';
import {
  AllActionsOptions,
  DealsActions,
  SubActionsOptions,
} from '@app/views/deals/interfaces/dealActions.interface';
import { DealActionStatus } from '@app/models/Enums/ActionStatusEnum';
import { Observable, map } from 'rxjs';
import { UserDTO, UserViewModel } from '@app/models/UserModels';
import { UserService } from '@app/services/user.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnChanges {
  @Input() actionTags: Array<string>;

  @Output() onEnter: EventEmitter<FiltersActionsName> = new EventEmitter();
  @Output() onSelectActions: EventEmitter<number> = new EventEmitter();

  public panelOpenState = false;
  public formFilter!: FormGroup;
  public dealSubOptions: SubActionsOptions[] = [];
  public dealActionOptions: AllActionsOptions[] = [];
  public dealActionStatus!: DealActionStatus;
  public insertActionForm!: FormGroup;
  public dataSource: DealsActions[] = [];
  public executives: UserDTO[] = [];

  public actionsValue: ActionStatus[] = [
    { value: 0, label: 'Pendiente' },
    { value: 1, label: 'Aprobado' },
    { value: 2, label: 'Rechazado' },
    { value: 3, label: 'Pendiente de Datos' },
  ];

  public filterNames: FiltersActionsName = {
    searchByRutName: '',
    actionFilter: 0,
    actionName: '',
    registerDate: '',
    warrantyType: '',
    tag: '',
    dataExecutive:''
  };

  constructor(
    private fb: FormBuilder,
    private dealService: DealsService,
    private userService: UserService
  ) {
    this.actionTags = new Array<string>();
  }

  ngOnInit(): void {
    this.formFilter = this.fb.group({
      searchByRutName: [''],
      actions: [''],
      actionName: [''],
      actionFilter: [0],
      warrantyType: [''],
      activityDate: [''],
      registerDate: [''],
      tag: [''],
      dataExecutive: [''],
    });
    this.fetchDealActionOptions();
    this.fetchExecutives();
    this.userService.getUsers().subscribe((e) => (this.executives = e));

  }
  ngOnChanges(changes: SimpleChanges): void {}
  fetchDealActionOptions = () => {
    this.dealService
      .getAllActionOptions()
      .subscribe((res) => (this.dealActionOptions = res));
  };

  public fetchExecutives(): void {
  }

  changeActionName(event: any): void {
    this.emitActionsFilter(event);
  }

  private emitActionsFilter(event: any): void {
const { name, lastName} = this.formFilter.get('dataExecutive')?.value
console.log(name, lastName)

    const filterNames: FiltersActionsName = {
      searchByRutName: this.formFilter.controls['searchByRutName']?.value,
      actionName: this.formFilter.controls['actionName']?.value,
      actionFilter: this.formFilter.controls['actionFilter']?.value,
      registerDate: this.formFilter.controls['registerDate']?.value,
      warrantyType: this.formFilter.controls['warrantyType']?.value,
      tag: this.formFilter.get('tag')?.value,
      dataExecutive: this.formFilter.get('dataExecutive')?.value,
    };
    console.log(filterNames)

    this.onEnter.emit(filterNames);
  }

  inputChangeValue(event: any): void {
    const inputValue = event.target.value;
    this.emitActionsFilter(inputValue);
  }
  changeStatus(event: any): void {
    this.onSelectActions.emit(event);
  }
}
