import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, map, Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { Company } from '../interfaces/company.interface';
import { CompanyContact } from '../interfaces/companyContact.interface';
import { CompanyDetail } from '../interfaces/companyDetail.interface';
import { CompanyDelete } from '../interfaces/companyDelete.interface';
import { NewDeal } from '../interfaces/companyDeal.interface';
import { ActivityItem } from 'src/app/models/ActivitiesModels';
import { environment } from 'src/environments/environment';
import { UpdateCompanyRequest } from 'src/app/models/Company/UpdateCompanyRequest';
import { ApiResponse } from '@app/models/Response';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  url = `${environment.apiUrl}/Company`;

  constructor(private http: HttpClient) {}

  getCompany(id: string): Observable<CompanyDetail> {
    let url = this.url + `/GetCompany/${id}`;
    return this.http.get<CompanyDetail>(url);
  }
  getCompanyContact(id: string): Observable<CompanyContact> {
    let url = this.url + `/GetCompanyContact/${id}`;
    return this.http.get<CompanyContact>(url);
  }

  getAllCompanyContact(id: string): Observable<CompanyContact[]> {
    let url = this.url + `/GetAllCompanyContact?Id=${id}`;
    return this.http.get<CompanyContact[]>(url);
  }

  getCompanyDetail(id: string): Observable<CompanyDetail> {
    let url = this.url + `/GetCompanyDetail/${id}`;
    return this.http.get<CompanyDetail>(url);
  }
  editDetailCompany(
    request: UpdateCompanyRequest
  ): Observable<CompanyDetail[]> {
    let url = this.url + '/EditCompanyDetail';
    return this.http.post<CompanyDetail[]>(url, request);
  }
  editCompanyContact(form: CompanyContact): Observable<ApiResponse<CompanyContact>> {
    let url = this.url + '/EditCompanyContact';
    return this.http.post<ApiResponse<CompanyContact>>(url, form);
  }
  deleteCompany(form: CompanyDelete): Observable<CompanyDelete> {
    let url = this.url + '/DeleteCompany';
    return this.http.post<CompanyDelete>(url, form);
  }
  createDeal(form: NewDeal): Observable<NewDeal> {
    let url = this.url + '/CreateDeal';
    return this.http.post<NewDeal>(url, form);
  }

  getCompanyActivities(id: string): Observable<ActivityItem[]> {
    const urlEndpoint = `${environment.apiUrl}/Company/${id}/activities`;
    return this.http.get<ActivityItem[]>(urlEndpoint);
  }
}
