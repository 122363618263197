<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <img src="assets/Lampara2.png" style="max-width: 20%; padding-left: -50px" />
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="8">
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <form cForm [formGroup]="loginForm" (ngSubmit)="login()" class="form-mat-field">
                <h1>Inicia Sesión</h1>
                <p class="text-medium-emphasis">con tu correo Wanak</p>

                <mat-form-field appearance="outline">
                  <mat-label>Correo eletrónico</mat-label>
                  <!-- <svg cIcon name="cilUser"></svg>                                     -->
                  <input
                    matInput
                    autoComplete="username"
                    cFormControl
                    placeholder="E-mail"
                    formControlName="email"
                  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label class="pass">Contraseña</mat-label>
                  <input
                    autoComplete="current-password"
                    cFormControl
                    placeholder="Contraseña"
                    type="password"
                    formControlName="password"
                    matInput
                    [type]="hide ? 'password' : 'text'"
                  />
                  <button
                    mat-icon-button
                    style="border: 0px; background: transparent"
                    matSuffix
                    (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide"
                  >
                    <mat-icon>{{
                      hide ? "visibility_off" : "visibility"
                    }}</mat-icon>
                  </button>
                </mat-form-field>

                <c-row>
                  <c-col xs="6">
                    <section>
                      <button
                        *ngIf="hideButton"
                        type="submit"
                        cButton
                        class="px-4"
                        color="primary"
                      >
                        Ingresar
                      </button>
                    </section>
                  </c-col>
                  <c-col
                    xs="12"
                    *ngIf="!hideButton"
                    class="d-flex justify-content-center"
                  >
                    <mat-spinner></mat-spinner>
                  </c-col>
                  <c-col xs="6">
                    <button
                      *ngIf="hideButton"
                      cButton
                      class="px-0"
                      style="font-size: 13px"
                      color="link"
                    >
                      Olvidé mi contraseña
                    </button>
                  </c-col>
                </c-row>
              </form>
            </c-card-body>
          </c-card>
          <c-card
            [ngStyle]="{ 'width.%': 44 }"
            class="text-white bg-primary py-5"
          >
            <c-card-body class="text-center">
              <div>
                <h2>Regístrate</h2>
                ------------------------
                <p>
                  Si eres nuevo en Wanak una empresa InGe! puedes registrar tu
                  correo acudiendo al equipo TI para que creen tu cuenta y
                  contraseña.
                </p>
                <!-- <button [active]="true" cButton class="mt-3" color="primary" routerLink="/register">
                  Registrar
                </button> -->
              </div>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>
